exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-blog-js": () => import("./../../../src/pages/blog.js" /* webpackChunkName: "component---src-pages-blog-js" */),
  "component---src-pages-contact-js": () => import("./../../../src/pages/contact.js" /* webpackChunkName: "component---src-pages-contact-js" */),
  "component---src-pages-enquiry-form-js": () => import("./../../../src/pages/enquiry-form.js" /* webpackChunkName: "component---src-pages-enquiry-form-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-our-services-financial-planning-insurance-income-protection-js": () => import("./../../../src/pages/our-services/financial-planning/insurance/income-protection.js" /* webpackChunkName: "component---src-pages-our-services-financial-planning-insurance-income-protection-js" */),
  "component---src-pages-our-services-financial-planning-insurance-js": () => import("./../../../src/pages/our-services/financial-planning/insurance.js" /* webpackChunkName: "component---src-pages-our-services-financial-planning-insurance-js" */),
  "component---src-pages-our-services-financial-planning-insurance-life-insurance-js": () => import("./../../../src/pages/our-services/financial-planning/insurance/life-insurance.js" /* webpackChunkName: "component---src-pages-our-services-financial-planning-insurance-life-insurance-js" */),
  "component---src-pages-our-services-financial-planning-investments-js": () => import("./../../../src/pages/our-services/financial-planning/investments.js" /* webpackChunkName: "component---src-pages-our-services-financial-planning-investments-js" */),
  "component---src-pages-our-services-financial-planning-js": () => import("./../../../src/pages/our-services/financial-planning.js" /* webpackChunkName: "component---src-pages-our-services-financial-planning-js" */),
  "component---src-pages-our-services-financial-planning-retirement-planning-js": () => import("./../../../src/pages/our-services/financial-planning/retirement-planning.js" /* webpackChunkName: "component---src-pages-our-services-financial-planning-retirement-planning-js" */),
  "component---src-pages-our-services-js": () => import("./../../../src/pages/our-services.js" /* webpackChunkName: "component---src-pages-our-services-js" */),
  "component---src-pages-our-services-returning-uk-expats-js": () => import("./../../../src/pages/our-services/returning-uk-expats.js" /* webpackChunkName: "component---src-pages-our-services-returning-uk-expats-js" */),
  "component---src-pages-our-services-savings-investments-js": () => import("./../../../src/pages/our-services/savings-investments.js" /* webpackChunkName: "component---src-pages-our-services-savings-investments-js" */),
  "component---src-pages-privacy-policy-js": () => import("./../../../src/pages/privacy-policy.js" /* webpackChunkName: "component---src-pages-privacy-policy-js" */),
  "component---src-pages-thank-you-js": () => import("./../../../src/pages/thank-you.js" /* webpackChunkName: "component---src-pages-thank-you-js" */),
  "component---src-pages-who-we-are-js": () => import("./../../../src/pages/who-we-are.js" /* webpackChunkName: "component---src-pages-who-we-are-js" */),
  "component---src-pages-who-we-are-our-approach-js": () => import("./../../../src/pages/who-we-are/our-approach.js" /* webpackChunkName: "component---src-pages-who-we-are-our-approach-js" */),
  "component---src-pages-who-we-are-our-team-js": () => import("./../../../src/pages/who-we-are/our-team.js" /* webpackChunkName: "component---src-pages-who-we-are-our-team-js" */),
  "component---src-templates-blog-post-js": () => import("./../../../src/templates/blogPost.js" /* webpackChunkName: "component---src-templates-blog-post-js" */)
}

